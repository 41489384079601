
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useToast } from "vue-toastification";
import { ref } from "yup";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Multiselect from "@vueform/multiselect";
import { Modal } from "bootstrap";

import moment from "moment";

const toast = useToast();

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Loading,
    Field,
    Multiselect,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      // Loading overlay
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,

      tableData: [],
      listSekolah: [],
      tableDataHistoryDetail: [],
      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },
      pagingmodal: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },
      name: "",
      schoolId: "",
      historyId: "",

      listStatus: [
        {
          _id: "OK",
          name: "OK",
        },
        {
          _id: "FAILED",
          name: "FAILED",
        },
      ],

      dateAll: [this.getDateFirst(), this.getDateNow()],
      startDate: "",
      endDate: "",

      status: "",

      detaildatasiswa: {
        name: "",
        nis: "",
        status: "",
        detail: "",
        content: "",
      },
      detaildatasiswacontent: {
        nama: "",
        nis: "",
        nisn: "",
        kelas: "",
        kelas_detail: "",
        jenis_kelamin: "",
        tanggal_lahir: "",
        tempat_lahir: "",
        tags: "",
        note: "",
      },
    };
  },

  beforeMount() {
    const store = useStore();

    store.dispatch(Actions.VERIFY_AUTH_FINANCE);
    this.schoolId = localStorage.getItem("schoolIdCardDesign")!;
    this.startDate = this.getDateFirst();
    this.endDate = this.getDateNow();

    // this.getListSekolah();
    this.getData(this.paging.size, this.paging.page);
    setCurrentPageBreadcrumbs("History Upload Data Excel", ["Data"]);
    // this.dateFrom = this.getDateNow();
    // this.dateUntil = this.getDateNow();
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    getData(size, page) {
      this.isLoading = true;

      const toast = useToast();

      let startDate = "";
      if (this.startDate) {
        startDate = "startDate=" + this.startDate + "&";
      }
      let endDate = "";
      if (this.endDate) {
        endDate = "endDate=" + this.endDate + "&";
      }
      let status = "";
      if (this.status) {
        status = "status=" + this.status + "&";
      }

      ApiService.getWithoutSlug(
        "crmv2/main_image/card/office/upload/" +
          this.schoolId +
          "/history/all?" +
          startDate +
          endDate +
          status +
          "page=" +
          page +
          "&size=" +
          size +
          "&sort=createTime&dir=-1"
      )
        .then(({ data }) => {
          this.tableData = data.content;
          this.paging.page = data.page;
          this.paging.size = data.size;
          this.paging.totalElements = data.totalElements;
          this.paging.totalPages = data.totalPages;

          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },

    detailHistory(val) {
      this.historyId = val._id;
      this.getDataDetailHistory(this.pagingmodal.size, this.pagingmodal.page);
    },
    getDataDetailHistory(size, page) {
      // const store = useStore();
      // store.dispatch(Actions.VERIFY_AUTH_FINANCE);
      ApiService.getWithoutSlug(
        "crmv2/main_card/card_upload/office/" +
          this.schoolId +
          "/" +
          this.historyId +
          "/all?page=" +
          page +
          "&size=" +
          size +
          "&sort=name&dir=1"
      )
        .then(({ data }) => {
          this.tableDataHistoryDetail = data.content;
          this.pagingmodal.page = data.page;
          this.pagingmodal.size = data.size;
          this.pagingmodal.totalElements = data.totalElements;
          this.pagingmodal.totalPages = data.totalPages;

          var modal2 = new Modal(document.getElementById("kt_modal_history"));
          modal2.show();

          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },

    detailHistorySiswa(val) {
      // const store = useStore();
      // store.dispatch(Actions.VERIFY_AUTH_FINANCE);
      console.log(val);

      ApiService.getWithoutSlug(
        "crmv2/main_card/card_upload/office/" +
          this.schoolId +
          "/" +
          this.historyId +
          "/" +
          val._id
      )
        .then(({ data }) => {
          // this.tableDataHistoryDetail = data.content;
          var decodeval = JSON.parse(data.content);
          console.log(data.content);
          console.log(decodeval);

          this.detaildatasiswa = data;
          this.detaildatasiswacontent = decodeval;
          console.log(this.detaildatasiswacontent);

          document.getElementById("kt_modal_history")!.style.display = "none";

          var modal = new Modal(
            document.getElementById("kt_modal_history_content")
          );
          modal.show();

          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },

    closeModalHistorySiswa() {
      document.getElementById("kt_modal_history")!.style.display = "block";
    },

    closeModal() {
      console.log("tes");
      location.reload();
    },
    closeModalContent() {},

    getListSekolah() {
      ApiService.getWithoutSlug("crmv2/main_school/school/school").then(
        ({ data }) => {
          this.listSekolah = data;
        }
      );
    },

    pilihSekolah(event) {
      // this.schoolId = event;
      if (event) {
        this.schoolId = event;
        this.getData(this.paging.size, this.paging.page);
      } else {
        this.schoolId = "";
        this.getData(this.paging.size, this.paging.page);
      }
    },

    onChangeSekolah() {
      this.getData(this.paging.size, this.paging.page);
    },
    pilihTanggalAll() {
      this.startDate = this.dateAll[0];
      this.endDate = this.dateAll[1];

      localStorage.setItem("dateAll", JSON.stringify(this.dateAll));
      localStorage.setItem("tanggalAwal", JSON.stringify(this.startDate));
      localStorage.setItem("tanggalAkhir", JSON.stringify(this.endDate));
      this.getData(this.paging.size, this.paging.page);
    },
    pilihStatus(event) {
      console.log(event);
      if (event) {
        this.status = event;
      } else {
        this.status = "";
      }
      this.getData(this.paging.size, this.paging.page);
    },

    refresh() {
      // location.reload();
      this.getData(this.paging.size, this.paging.page);
    },

    downloadData() {
      const toast = useToast();
      // toast.error("File Belum Ada");
      this.isLoading = true;
      let startDate = "";
      if (this.startDate) {
        startDate = "startDate=" + this.startDate + "&";
      }
      let endDate = "";
      if (this.endDate) {
        endDate = "endDate=" + this.endDate + "&";
      }
      let status = "";
      if (this.status) {
        status = "status=" + this.status + "&";
      }

      ApiService.getDownload(
        "crmv2/main_image/card/office/upload/" +
          this.schoolId +
          "/history/download?" +
          startDate +
          endDate +
          status
      )
        .then((response) => {
          // this.handleBlobResponse(response.data, item, extension);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;

          // console.log(res.data);
          var extension = "xlsx";
          link.setAttribute(
            "download",
            "History_Upload_Foto" + "." + extension
          ); // nama file dan extension sesuaikan dengan file yang di download
          document.body.appendChild(link);
          link.click();
          this.isLoading = false;

          toast.success("Sukses Unduh File");
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          toast.error("Gagal Unduh File");
          this.isLoading = false;
        });
    },

    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize(e) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },

    nextPageModal() {
      const page = this.pagingmodal.page + 1;
      this.getDataDetailHistory(this.pagingmodal.size, page);
    },
    prevPageModal() {
      const page = this.pagingmodal.page - 1;
      this.getDataDetailHistory(this.pagingmodal.size, page);
    },
    changePageSizeModal(e) {
      const page = this.pagingmodal.page;
      this.getDataDetailHistory(this.pagingmodal.size, page);
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    formatDate: function (date) {
      // return moment(date, "D-MM-YYYY HH:mm").format("D-MM-YYYY HH:mm");
      return moment(date).format("YYYY-MM-DD HH:mm");
    },

    date(val) {
      if (val) {
        return moment(val).format("YYYY-MM-DD");
      } else {
        return "";
      }
    },

    dateTime(val) {
      if (val) {
        let text = val;
        let result = text.includes("T");
        let jam = "";
        if (result == true) {
          // let text = "2022-04-05T09:45:32.719896";
          let val = text.split("T");
          let subst = val[1].substr(0, 8);
          jam = subst;
        } else {
          jam = "hh:mm";
        }
        return moment(val).format("YYYY-MM-DD " + jam);
      } else {
        return "-";
      }
    },

    getDateNow() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      var rslt = yyyy + "-" + mm + "-" + dd;
      return rslt;
    },
    getDateFirst() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      var rslt = yyyy + "-" + mm + "-01";
      return rslt;
    },
  },
});
